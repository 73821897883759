import { Component, OnInit } from "@angular/core";
import { AccountService } from '@services/account.service';

declare var require;
const Swal = require('sweetalert2');

@Component({
    selector: "app-my-account",
    templateUrl: "./my-account.component.html",
    styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
    public userName: string;
    public profileImg: "assets/images/dashboard/profile.jpg";

    account = this.accountService.accountValue;

    constructor(
        private accountService: AccountService
    ) { }

    ngOnInit() {}

    logout() {
        Swal.fire({
            icon: 'question',
            title: 'Cerrar Sesión',
            text: 'Seguro de cerrar sesión.',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            customClass: 'swal2-sm'
        })
        .then((result) => {
            if (result.value) {
                this.accountService.logout();
            }
        });
    }
}
