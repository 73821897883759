<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
    <div class="header-wrapper row m-0">
        <div class="header-logo-wrapper col-auto p-0">
            <div class="logo-wrapper">
                <a routerLink="/">
                <img class="img-fluid for-light" src="assets/images/logo/logo_dark.png" alt="" />
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" />
                </a>
            </div>
            <div class="toggle-sidebar" (click)="sidebarToggle()">
                <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
            </div>
        </div>
        <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
            <ul class="nav-menus">
                <li class="onhover-dropdown">
                    <app-notification></app-notification>
                </li>
                <li class="onhover-dropdown">
                    <app-message-box></app-message-box>
                </li>
                <li class="maximize">
                    <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
                        <app-feather-icons [icon]="'maximize'"></app-feather-icons>
                    </a>
                </li>
                <li class="profile-nav onhover-dropdown p-0">
                    <app-my-account></app-my-account>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Page Header Ends -->
